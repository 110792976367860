import React from 'react'

import Navi from '../Navi'
import Footer from '../Footer'

import '../../scss/pensoco.scss'
import './style.scss'

class Layout extends React.Component {
  render() {
    const { children } = this.props
    return (
      <div className="layout">
        <Navi />
        <div className="content animated fadeIn">
          {children}
        </div>
        <Footer />
      </div>
    )
  }
}

export default Layout
