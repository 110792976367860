import React from 'react'

import AniLink from 'gatsby-plugin-transition-link/AniLink'
import logo from '../../images/logo-skully.svg'
import './style.scss'

class Navi extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      menuActive: false,
    }
  }

  componentDidMount() {
    document.body.className = ''
  }

  toggleMenu = () => {
    this.setState({ menuActive: !this.state.menuActive }, () => {
      if (this.state.menuActive) {
        document.body.className = 'menu-active'
      } else {
        document.body.className = ''
      }
    })
  }

  render() {
    const { menuActive } = this.state
    return (
      <>
        <nav className="navbar navbar-dark fixed-top">
          <AniLink to="/" cover direction="right" duration={1} bg="#b88343">
            <h1>Penso Co.</h1>
          </AniLink>
          <AniLink to="/" cover direction="right" duration={1} bg="#b88343">
            <img src={logo} className="skully" alt="Penso Co. skull logo" />
          </AniLink>
          <button
            className={
              'hamburger hamburger--squeeze ' + (menuActive ? 'is-active' : '')
            }
            type="button"
            onClick={this.toggleMenu}
          >
            <i>Menu</i>
            <span className="hamburger-box">
              <span className="hamburger-inner" />
            </span>
          </button>
        </nav>
        <aside className={menuActive ? 'is-active' : ''}>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-3">
                <nav className="nav--global">
                  <ul>
                    <li>
                      <AniLink
                        cover
                        duration={1}
                        bg="#b88343"
                        to="/work"
                        activeClassName="active"
                      >
                        Work
                      </AniLink>
                    </li>
                    <li>
                      <AniLink
                        cover
                        duration={1}
                        bg="#b88343"
                        to="/what-we-do"
                        activeClassName="active"
                      >
                        What We Do
                      </AniLink>
                    </li>
                    <li>
                      <AniLink
                        cover
                        duration={1}
                        bg="#b88343"
                        to="/studio"
                        activeClassName="active"
                      >
                        Studio
                      </AniLink>
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="col-xs-6 col-md-3">
                <h6>Say Hello</h6>
                <nav className="nav--social">
                  <ul>
                    <li>
                      <a href="mailto:hello@penso.co">hello@penso.co</a>
                    </li>
                    <li>
                      <a href="tel:503-694-9222">(503) 694-9222</a>
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="col-md-3">
                <h6>Pop In</h6>
                <address>
                  <p>
                    100 NE Farragut St
                    <br />
                    Studio 8<br />
                    Portland, OR 97211
                  </p>
                  <p>
                    <a
                      href="https://goo.gl/maps/jmkQ44THFYiFSbmB9"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className="icon-map" />
                      <span className="sr-only">View Google Map location of Penso Co.'s studio</span>
                    </a>
                  </p>
                </address>
              </div>
            </div>
          </div>
        </aside>
      </>
    )
  }
}

export default Navi
