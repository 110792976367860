import React from 'react'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import get from 'lodash/get'
import favicon from '../../images/favicon.ico'

const Meta = ({ title, location }) => {
  const data = useStaticQuery(graphql`
    query MetaQuery {
      site {
        meta: siteMetadata {
          title
          description
          siteUrl: siteUrl
          twitter
        }
      }
    }
  `)
  const metaData = get(data.site, 'meta')
  const siteTitle = get(metaData, 'title')
  if (location === '/') {
    title = `${title}`;
  } else {
    title = title ? `${title} | ${siteTitle}` : siteTitle
  }

  return (
    <Helmet
      htmlAttributes={{lang: 'en'}}
      title={title}
      meta={[
        { name: 'description', content: `${get(metaData, 'description')}`},
        { name: 'twitter:card', content: 'summary_large_image' },
        {
          property: 'twitter:image',
          content: `${get(metaData, 'siteUrl')}/images/penso-co-og.png`,
        },
        {
          name: 'twitter:site',
          content: `@${get(metaData, 'twitter')}`,
        },
        { property: 'og:title', content: title },
        { property: 'og:type', content: 'website' },
        {
          property: 'og:description',
          content: get(metaData, 'description'),
        },
        {
          property: 'og:url',
          content: `${get(metaData, 'siteUrl')}`,
        },
        {
          property: 'og:image',
          content: `${get(metaData, 'siteUrl')}/images/penso-co-og.png`,
        },
        {
          name: 'google-site-verification',
          content: '9aO8pfZYGoyoIE2yODQm2x5howyutyCH0-ZgOsC42Pk'
        }
      ]}
      link={[
        { rel: 'shortcut icon', type: 'image/x-icon', href: `${favicon}` },
      ]}
    />
  )
}
export default Meta
