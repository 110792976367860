import React from 'react'
import './style.scss'

import logo from '../../images/penso-co-gold.png'

const Footer = () => (
	<footer className="pt-5 pb-4">
		<div className="container small">
			<div className="row">
				<div className="col-s-6 col-md-3">
					<p>
						<img src={logo} alt="Penso Co. logo" />
					</p>
				</div>
				<div className="col-s-6 col-md-3">
					<address>
						<p>
							100 NE Farragut St<br />
							Studio 8<br />
							Portland, OR 97211
						</p>
					</address>
				</div>
				<div className="col-md-3">
					<p>
						<a href="https://instagram.com/pensocostudio" target="_blank" rel="noopener noreferrer">
							Instagram<span className="icon-open-small"></span>
						</a>
						<br />
						<a href="https://twitter.com/pensocostudio" target="_blank" rel="noopener noreferrer">
							Twitter<span className="icon-open-small"></span>
						</a>
						<br />
						<a
							href="https://www.linkedin.com/company/pensocostudio"	target="_blank" rel="noopener noreferrer">
							LinkedIn<span className="icon-open-small"></span>
						</a>
					</p>
				</div>
				<div className="col-md-3 contact">
					<p>
						<a href="mailto:hello@penso.co">hello@penso.co</a>
						<br />
						<a href="tel:503-694-9222">(503) 694-9222</a>
					</p>
				</div>
				</div>
			</div>
	</footer>
)

export default Footer
